import React from "react"

const Footer = () => {
  return (
    <div className="container py-12 md:flex md:items-center md:justify-between">
      <div className="mt-8 md:mt-0 md:order-1">
        <p className="text-center text-sm md:text-base text-gray-700">		
		&copy; 2020 Baron Sturgess
        </p>
      </div>
    </div>
  )
}


export default Footer
