import { graphql } from "gatsby"
import React from "react"
import Cards from "../components/Cards"
import Hero from "../components/Hero"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"


const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SiteMetadata title="Doug's Log" description="Lazy but Creative" />

      <Hero />

      <div className="bg-blue-100 py-4 lg:py-8">
	      {data.HomophonesJson && data.HomophonesJson.nodes.length > 0 ? (
          <Cards items={data.HomophonesJson.nodes} />
        ) : (
          <div className="container">No projects found.</div>
        )}
      </div>

    </Layout>
  
  )}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    HomophonesJson: allHomophonesJson {
       nodes {
       ...HomophoneCard
    }}
  }
`
