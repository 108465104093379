import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import doug from "../images/doug.png"

const AboutPage = () => (
  <Layout>
    <SiteMetadata title="About" description="Doug from Homphonic Nosebleed" />

    <div className="bg-gray-100">
      <div className="container py-6 lg:pb-16">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pb-0">
            <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              About Doug
            </h1>
	    <img className="object-contain h-80 pt-16 align-bottom mb-48 inline-block ..." src={doug} alt="One-job"/>
			  
            <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl -mt-64">
              He Had One Job
            </h2>
            <div className="mt-4 leading-loose">
		    Doug works for what you would call a census of all things in the galaxy.  This agency does a lot of good.  But Doug sucks at his job.  The work presented here is what happens when Doug realizes his field report on Earth is due in a week.  We generally think he just breaks out, in your terms, an encyclopedia and a dictionary.  Regardless, might as well start crowd-correcting now.
              <br />
              <br />
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-2/5 md:pl-12">
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutPage
